import { NextSeo } from 'next-seo'
import { extService, taxService } from '@utils/magento2'
import { useEffect, useMemo, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import {
  sendProductGTMEvent,
  sendListGTMEvent,
  sendCategoryListGTMEvent,
} from '@utils/helpers/gtm'
import { parseQueryParams } from '@utils/helpers/parseQueryParams'
import { resolveUrl } from '@utils/helpers/resolveUrl'
import { calculateTaxRate } from '@utils/helpers/calculateTaxRate'
// import { fetchStoryblokData } from '@utils/helpers/fetchStoryblokData'
import { processCategory } from '@utils/helpers/processCategory'
import { processProduct } from '@utils/helpers/processProduct'
import { calculateCurrencyConversion } from '@utils/helpers/calculateCurrencyConversion'
import { withGetStaticProps } from '@utils/helpers/withGetStaticProps'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import useI18n from '@utils/hooks/useI18n'
import { appConfig } from '@utils/appConfig'

// Constants for page types and defaults
const PAGE_TYPES = {
  CMS_PAGE: 'cms_page',
  PRODUCT: 'product',
  CATEGORY: 'category',
  CONFIG: 'config',
}

const DEFAULT_META_DESCRIPTION =
  'High quality equipment, professional advice and custom-made products at the best prices! Simply lease or buy cheap. Fast and free delivery. 24h hotline.'

// Dynamic imports with loading optimization

const Category = dynamic(() => import('@components/Pages/Category'), {
  ssr: true,
  loading: () => <div className="min-h-[60vh]" />,
})

const ProductPage = dynamic(() => import('@components/Pages/ProductPage'), {
  ssr: true,
  loading: () => <div className="min-h-[60vh]" />,
})

const MPBPage = dynamic(() => import('@components/MagentoPageBuilder'), {
  ssr: true,
  loading: () => <div className="min-h-[60vh]" />,
})

// Memoized page content renderer
const PageContent = memo(({ type, props }) => {
  const pages = useMemo(
    () => ({
      [PAGE_TYPES.CMS_PAGE]: <MPBPage {...props} />,
      [PAGE_TYPES.PRODUCT]: <ProductPage {...props} />,
      [PAGE_TYPES.CATEGORY]: <Category {...props} />,
      [PAGE_TYPES.CONFIG]: (
        <div className="flex min-h-[60vh] w-full items-center justify-center" />
      ),
    }),
    [props],
  )

  return pages[type]
})

PageContent.displayName = 'PageContent'

// Memoized SEO component
const SeoComponent = memo(
  ({ type, source, asPath, language, country, currency }) => {
    const i18n = useI18n()

    const title = useMemo(
      () =>
        source?.meta_title
          ? `${source.meta_title} | GGM Gastro`
          : source?.name
            ? `${source.name} | GGM Gastro`
            : 'GGM Gastro',
      [type, source?.meta_title, source?.name],
    )

    const description = useMemo(
      () =>
        source?.meta_description
          ? source.meta_description.replace(/<[^>]*>/g, '')
          : i18n.t([DEFAULT_META_DESCRIPTION]),
      [source?.meta_description, i18n],
    )

    return (
      <NextSeo
        title={title}
        description={description}
        noindex={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
          asPath &&
          !asPath.includes('?')
            ? !(
                appConfig.storeViews[language]?.seo?.index?.country?.includes(
                  country,
                ) &&
                appConfig.storeViews[language]?.seo?.index?.currency?.includes(
                  currency,
                )
              )
            : true
        }
        nofollow={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
          asPath &&
          !asPath.includes('?')
            ? !(
                appConfig.storeViews[language]?.seo?.follow?.country?.includes(
                  country,
                ) &&
                appConfig.storeViews[language]?.seo?.follow?.currency?.includes(
                  currency,
                )
              )
            : true
        }
      />
    )
  },
)

SeoComponent.displayName = 'SeoComponent'

// Main Slug component optimized
const Slug = memo(
  ({
    source,
    type,
    language,
    country,
    currency,
    domain,
    categoryTopBlocks = {},
    categoryBottomBlocks = {},
    ...props
  }) => {
    const router = useRouter()
    const breadcrumbs = useSelector((state) => state.seo.breadcrumbs)

    // Memoized GTM event handler
    const handleGTMEvent = useCallback(() => {
      if (type === PAGE_TYPES.PRODUCT) {
        sendProductGTMEvent(source, breadcrumbs, props.country, currency)
        if (source.spare_parts?.length > 0) {
          sendListGTMEvent(
            source.spare_parts,
            breadcrumbs,
            props.country,
            currency,
            'spare_parts',
            0,
          )
        }
        if (source.accessories?.length > 0) {
          sendListGTMEvent(
            source.accessories,
            breadcrumbs,
            props.country,
            currency,
            'accessories',
            0,
          )
        }
      } else if (type === PAGE_TYPES.CATEGORY) {
        if (
          source?.products?.length > 0 &&
          props?.displayMode === 'detail_slider'
        ) {
          sendListGTMEvent(
            source.products,
            breadcrumbs,
            props.country,
            currency,
            'product list',
            router?.query?.page > 1 ? 20 * (router.query.page - 1) : 0,
          )
        }
      }
      if (
        (type === PAGE_TYPES.PRODUCT || type === PAGE_TYPES.CATEGORY) &&
        source?.children_data?.length > 0
      ) {
        sendCategoryListGTMEvent(source.children_data)
      }
    }, [type, source, breadcrumbs, props.country, currency])

    useEffect(() => {
      handleGTMEvent()
    }, [router.asPath])

    // Memoized props for child components
    const pageProps = useMemo(
      () => ({
        type,
        source,
        categoryTopBlocks,
        categoryBottomBlocks,
        language,
        country,
        currency,
        domain,
        ...props,
      }),
      [type, source, categoryTopBlocks, categoryBottomBlocks, props],
    )

    return (
      <>
        <SeoComponent
          type={type}
          source={source}
          language={language}
          country={country}
          currency={currency}
          asPath={router?.asPath}
          domain={domain}
        />
        <PageContent type={type} props={pageProps} />
      </>
    )
  },
)

Slug.displayName = 'Slug'

// Helper function to sanitize slug
const sanitizeSlug = (slug) =>
  slug.filter((item) => !item.startsWith('querystring.')).join('/')

// Helper function to find slug data
const findSlugData = (hits, slug) =>
  hits.find(
    (item) =>
      item._source.url_path === slug ||
      item._source.identifier === slug ||
      item._source.slug === slug ||
      item._source.sku === slug.replace('sku-', ''),
  ) || hits[0]

// Helper function to handle redirects
const handleRedirects = (source, type, slug, language, country, currency) => {
  if (type === PAGE_TYPES.PRODUCT && slug.startsWith('sku-')) {
    return {
      destination: `/${language}-${country}-${currency}/${source.url_path}`,
      statusCode: 301,
    }
  }

  if (type === 'url_rewrite_index' && source.target_path) {
    return {
      destination: `/${language}-${country}-${currency}/${source.target_path}`,
      statusCode: 301,
    }
  }

  if (type === PAGE_TYPES.CATEGORY && source.cms_content_page) {
    return {
      destination: `/${language}-${country}-${currency}/${source.cms_content_page}`,
      permanent: false,
    }
  }

  return null
}

// Helper function to handle category or product data processing
const handleCategoryOrProduct = async (
  source,
  type,
  language,
  country,
  currency,
  query,
) => {
  const [currencyData, taxData] = await Promise.all([
    extService.getCurrency(),
    taxService.getTax(language),
  ])

  const currencyConversion = calculateCurrencyConversion(currencyData, currency)
  const taxRate = calculateTaxRate(taxData, country)

  if (type === PAGE_TYPES.CATEGORY) {
    source = await processCategory(
      source,
      language,
      country,
      query,
      currencyConversion,
      taxRate,
    )
  } else if (type === PAGE_TYPES.PRODUCT) {
    source = await processProduct(
      source,
      language,
      country,
      currencyConversion,
      taxRate,
    )
  }

  return [source, type]
}

// Helper function to validate locale format
const isValidLocaleFormat = (locale) =>
  /^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(locale)

// Helper function to process page data
const processPageData = async (slug, language, country, currency) => {
  const sanitizedSlug = sanitizeSlug(slug)

  if (sanitizedSlug === 'pageconfig') {
    return {
      props: {
        type: PAGE_TYPES.CONFIG,
        source: {},
        query: {},
        language,
        country,
        currency,
      },
      revalidate: 1800,
    }
  }

  try {
    const [query, resolvedData] = await Promise.all([
      Promise.resolve(parseQueryParams(slug)),
      resolveUrl(sanitizedSlug, language, country),
    ])
    if (!resolvedData?.hits?.hits?.length) {
      return {
        notFound: true,
        revalidate: 30,
      }
    }

    const slugData = findSlugData(resolvedData.hits.hits, sanitizedSlug)
    if (!slugData) {
      return {
        notFound: true,
        revalidate: 30,
      }
    }

    let { _source, _type } = slugData

    const redirect = handleRedirects(
      _source,
      _type,
      sanitizedSlug,
      language,
      country,
      currency,
    )
    if (redirect) return { redirect }

    try {
      const [processedData, storyblokData] = await Promise.all([
        _type === PAGE_TYPES.CATEGORY || _type === PAGE_TYPES.PRODUCT
          ? handleCategoryOrProduct(
              _source,
              _type,
              language,
              country,
              currency,
              query,
            )
          : [_source, _type],
        { categoryTopBlocks: {}, categoryBottomBlocks: {} },
        // _type === PAGE_TYPES.CATEGORY
        //   ? fetchStoryblokData(language, country, currency, sanitizedSlug)
        //   : { categoryTopBlocks: {}, categoryBottomBlocks: {} },
      ])

      ;[_source, _type] = processedData

      const safeSource = JSON.parse(JSON.stringify(_source))

      return {
        props: {
          source: safeSource,
          categoryTopBlocks: storyblokData.categoryTopBlocks || {},
          categoryBottomBlocks: storyblokData.categoryBottomBlocks || {},
          type: _type,
          displayMode:
            _type === PAGE_TYPES.CATEGORY
              ? _source.display_mode_akeneo || 'detail_slider'
              : null,
          query,
          language,
          country,
          currency,
          updated: new Date().toISOString(),
        },
        revalidate: 1800,
      }
    } catch (innerError) {
      console.error('Error processing category/product data:', innerError)
      return {
        notFound: true,
        revalidate: 30,
      }
    }
  } catch (error) {
    console.error('Error processing page data:', error)
    return {
      notFound: true,
      revalidate: 30,
    }
  }
}

// Get static props implementation
export const getStaticProps = withGetStaticProps(async ({ params }) => {
  try {
    const { locales, slug } = params

    if (!isValidLocaleFormat(locales)) {
      return { notFound: true, revalidate: 30 }
    }

    const [language, country, currency] = locales.split('-')
    return processPageData(slug, language, country, currency)
  } catch (error) {
    console.error('Error in getStaticProps:', error)
    return { notFound: true, revalidate: 30 }
  }
})

// Get static paths implementation
export const getStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
})

export default Slug
